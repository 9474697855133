@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@100&display=swap');
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&amp;display=swap"); */
@import url(//netdna.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.css);
@import url(./assets/css/vendor/vendor.min.css);
@import url(./assets/css/plugins/plugins.min.css);
@import url(./assets/css/style.min.css);
@import url(./assets/css/aos.css);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

/* @import url('https://fonts.googleapis.com/css2?family=STIX+Two+Text&display=swap'); */
/* @import url(./assets/NeueMontreal-Regular.otf); */
.dot-carousel {
    position: relative;
    left: -9999px;
    width: 10px !important;
    height: 10px !important;
    border-radius: 5px !important;
    background-color: #9880ff;
    color: #9880ff;
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
    animation: dotCarousel 1.5s infinite linear;
}

@keyframes dotCarousel {
  0% {
    box-shadow: 9984px 0 0 -1px #9880ff, 9999px 0 0 1px #9880ff, 10014px 0 0 -1px #9880ff;
  }
  50% {
    box-shadow: 10014px 0 0 -1px #9880ff, 9984px 0 0 -1px #9880ff, 9999px 0 0 1px #9880ff;
  }
  100% {
    box-shadow: 9999px 0 0 1px #9880ff, 10014px 0 0 -1px #9880ff, 9984px 0 0 -1px #9880ff;
  }
}


.mainLoginInput{
  /* height: 40px; */
  padding: 0px;
  /* font-size: 30px; */
  margin: 5px 0;
}

.mainLoginInput::-webkit-input-placeholder { 
font-family: FontAwesome;
font-weight: normal;
overflow: visible;
vertical-align: top;
display: inline-block !important;
padding-left: 5px;
padding-top: 2px;
/* color: hsl(9, 40%, 60%); */
}

.mainLoginInput::-moz-placeholder  { 
font-family: FontAwesome;
font-weight: normal;
overflow: visible;
vertical-align: top;
display: inline-block !important;
padding-left: 5px;
padding-top: 2px;
/* color: hsl(9, 40%, 60%); */
}

.mainLoginInput:-ms-input-placeholder  { 
font-family: FontAwesome;
font-weight: normal;
overflow: visible;
vertical-align: top;
display: inline-block !important;
padding-left: 5px;
padding-top: 2px;
/* color: hsl(9, 40%, 60%); */
}


