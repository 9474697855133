.product .thumb .badges span.new {
    background-color: #ee7600;
}
.product .content .price span.old {
    font-size: 14px;
    margin-left: 10px;
    text-decoration: line-through;
    color: #ee7600;
}
.maindiv{
    height:50%;
    justify-content: space-between;
    padding:5px 5px 5px 5px;
    border-radius: 5px;
    border:1px solid rgb(185, 184, 184);
    background-color: #fff;
}
h4{
    font-family: "Open Sans",sans-serif;
    padding:0 0 5px 0;
    margin: 0;
    color: #4f585e;
    font-size:20px;
    font-weight: 700;    
}
.hospimg{
    margin: 0;
    padding: 0;
    overflow:hidden;    
}
.imgdiv{
    margin: 0;
    padding: 0;
}
#myimg{
    padding:0;
    margin: 0; 
    height:100%;
    width: 100%;
}
.hospdesc{
    padding: 0 5 10px 5px;
    margin: 0;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.tb{
    padding: 0;
    margin: 0;
    color: #3a3737;
    font-family: "Open Sans",sans-serif;
}
.cno{
    padding: 0;
    margin: 0;
    color: #17181f;
    font-family: "Open Sans",sans-serif;
}
.add{
    padding: 0;
    margin: 0;
    color: #cb4d40;
    font-family: "le-monde-livre-classic-byol",serif;
}
.ud{
    padding: 0;
    margin: 0;
    font-family: 'Times New Roman', Times, serif;
    color: #3a3737;
}
#icon1{
    margin:0;
    padding: 0;
}
.button1{
    height: 35px;
    width: 140px;
   border-radius: 4px;
   border-style: none;
    background-color:#fc8019;
    /*#3bb896; */
    color: #fff;
    margin: 0;
}
.row1_procard{
    background-color:#fdf2d2;
    /* #99edcac5 ; */
    height: 35%;   
    width: 100%;
    font-size: large;
    color: #fff;
    padding:5px 5px 0 5px;
    margin:0;
    border-radius: 10px 0 10px 0;
}
.checked {
    color: orange;
}
.unchecked{
    color: #212121;
}
.row1_procard span{
    margin: 0;
    padding: 0;
    
}
@media(min-width:761px){
    .tb{
        font-size: medium;
    }
    .ud{
        font-size: medium;       

    }
    .add{
        font-size: medium;        
    }
    .cno{
        font-size: medium;
        }
    .row1_procard
        {
            font-size: medium;
        }
}
@media(max-width:760px){
    .tb{
        font-size:large;
        
    }
    .ud{
        font-size: large;
        

    }
    .add{
        font-size: large;
        

    }
    .cno{
        font-size: large;
    

    }
}
@media(max-width:480px)
{
    .tb{
        font-size: small;
        
    }
    .ud{
        font-size: small;
        

    }
    .add{
        font-size: small;
        

    }
    .cno{
        font-size: small;
        

    }

}
@media(max-width:360px)
{
    .tb{
        font-size: x-small;
        
    }
    .ud{
        font-size: x-small;
        

    }
    .add{
        font-size: x-small;
        

    }
    .cno{
        font-size: x-small;
        

    }
}
.ext_addto{
    visibility: visible;
    transform: translateY(0);
    opacity: 1;

    background-color: #4c9090 ;
    color: #FFFFFF;
    display: block;
    width: 80%;
    height: 45px;
    line-height: 45px;
    
    text-align: center;
    border: 0;
    border-radius: 3px;
    font-size: 14px;
    position: relative;
    bottom: 20px;
    left: 0;
    right: 0;
    z-index: 11;
    margin: auto;
    font-weight: 600;
    /* visibility: hidden; */
    transition: all .3s ease 0s;
    transform: translateY(20px);
    /* color: #3f3f3f; */
    /* opacity: 0; */
    font-family: Raleway,sans-serif;
}

    

