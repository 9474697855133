#faq .panel-body{
    padding:10px 10px;
    
}
#faq .panel{
    /* width: 60%; */
}
.collapse_pan img{
    max-width: 40%;
    height: auto;
    border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}
.no-border{
    border:none !important;
}
.panel-collapse a{
    color: #14955f !important;
    font-weight: 700;
    text-decoration: underline;
}
.single-my-account .panel-title.h3, .single-my-account h3.panel-title{
    text-transform:unset !important;
    font-size:17px;
}