
.product .thumb .image img{
    /* height: 90%; */
    width: auto !important;
    max-width: 90% !important;
    max-height: 200px !important;

    border-radius: 10px;
}

.product {
    /* border-radius: 10px; */
    overflow: hidden;
    background-color: #ffffffa8;
    padding-bottom: 5px;
}
.product .content{
    background-color: #ffffff29;
}


.swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: 50%;
    width: calc(var(--swiper-navigation-size)/ 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(-1 * var(--swiper-navigation-size)/ 2);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color,var(--swiper-theme-color));
}
.page__title-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.slider-cover__prev::after {
    font-size: 1rem !important;
    color: #000 !important;
}
.slider-cover__next::after {
    font-size: 1rem !important;
    color: #000 !important;
}
.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
    content: 'prev';
}
.page__title-right {
    position: relative;
    display: flex;
    align-items: center;
}
.slider-cover__prev {
    position: relative;
    right: auto;
    left: auto;
    top: auto;
    bottom: 0px;
    margin-right: 10px;
}
.slider-cover__next {
    position: relative;
    right: auto;
    left: auto;
    top: auto;
    bottom: 0px;
}
.swiper-button-next:after, .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none!important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
}