.footer-bottom .payment-link img{
     height: 30px;
    width: 55px; 
}
.product-details-content .pro-details-rating-wrap{
    justify-content: center;
}
.product-details-content .pro-details-quality{
    justify-content: center;
}
.product-details-content .pro-details-quality{
    justify-content: center;
}
.product-details-content .pro-details-wish-com{
    justify-content: center;
}
.product-details-content .pro-details-social-info{
    justify-content: center;
}
.description-review-bottom p{
    font-size: 19px !important;
}
.privacy_content p{
    font-size: 19px !important;
}