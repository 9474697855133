.breadcrumb-area{
     /* padding-top: 400px;
    padding-bottom: 80px; */
    
     /* padding-top: 50%;  */
      /* top:50%; */
    max-width: 100%;
}
.breadcrumb-area {
  background-color: #b98648d9;
}
.breadcrumb-area-4{
  padding-top: 40%; 
      top:50%;
    max-width: 100%;
}
.breadcrumb-area-3{
  padding-top: 30%; 
      top:50%;
    max-width: 100%;
}

.breadcrumb_box h1{
  padding-bottom: 10px;
}  
.breadcrumb-item a,h4,h5,h3,h2,h1{
  /* font-size: large; */
  color: #00aaef;
  font-weight: 700;

  /* text-decoration: underline; */
}
h2{
  font-size: 29px !important;
  text-transform: capitalize;
}
/*
.inner-banner{
    filter: blur(8px);
    -webkit-filter: blur(8px);
    height: 100%;

  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
*/
 .banner-bg-text1 {
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
    color: white;
    font-weight: bold;
    /* border: 3px solid #f1f1f1; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
  }
  @media only screen and (max-width: 600px){
   
    .breadcrumb_box{

      /* top: 20%;
      position: absolute; */
      text-align: center;
      max-width: 80%;/* imp*/
      max-height: 100vh;
      overflow: hidden;
      }
  }
  @media only screen and (min-width: 700px){
    .breadcrumb_box{

      /* top: 40%;
      position: absolute; */
      text-align: center;
      max-width: 95%;/* imp*/
      max-height: 100vh;
      overflow: hidden;
      }
      .breadcrumb_box-4{

        top: 30%;
        position: absolute;
        text-align: center;
        max-width: 80%;/* imp*/
        max-height: 100vh;
        overflow: hidden;
        }
        .breadcrumb_box-3{

          top: 20%;
          position: absolute;
          text-align: center;
          max-width: 80%;/* imp*/
          max-height: 100vh;
          overflow: hidden;
          }
  }
  