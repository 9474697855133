.header-bottom {
    padding: 3px 0;
}
.sticky-nav.menu_fixed{
    background-color: #2b2f33a6 !important;
}
@media only screen and (max-width: 600px){
    /* For portrait layouts only */
    .header-logo img{
        width:auto;
        height: 32px;
    }
  }
  @media only screen and (min-width: 600px){
    /* For portrait layouts only */
    .header-logo img{
        /* max-width: 90px !important;
        height: 55px; */
    }
  }
@media (max-width: 600px ) {
   
}


.bg-black{
    background-color:#dc7d09;
    
    /* background-color: ; */
}
.main-menu ul li.dropdown ul li a{
    height: auto;
    
}
.main-menu ul li a{
    font-size: 16px;
    text-transform: capitalize !important;
     /* font-weight: 600; */
}
.main-menu ul li.dropdown ul li a{
    font-weight: bold;
}
.header-top-massege p{
    color:#fff;
}
.header-top-massege1 p{
    color:#fff;
}
.flex-grow-2{
    flex-grow:2 !important;;
}
.header-top{
    padding:1px 1px;
}
.header-action-btn a i::before{
   /* margin-top: 5px; */
}
.btn-warning {
    color: #000;
    background-color: #ff7004;
    border-color: #fff;
}
@media only screen and (max-width: 600px){
    /* For portrait layouts only */
    .header-logo img{
        max-width:auto;
        height: 32px;
    }
    .custom-search{
        display: none;
    }
    
  }
  @media only screen and (min-width: 600px){
    /* For portrait layouts only */
    .header-logo img{
        /* max-width: 60px !important;
        height: 40px; */
    }
    .custom-search{
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 100px;
        padding-right: 100px;

    }
    .custom-search select{
        font-size: medium;
        background-color: #e9ecef;
    }
    .header_account_list{
        display: none;
    }
  }
@media (max-width: 600px ) {
   
}
.sticky-header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    animation: sticky 1s;
    box-shadow: 2px 4px 8px rgb(51 51 51 / 25%);
}
.dropdown a{
    color:#000 !important;
}
.header-actions>*+* {
    padding-left: 0px;
    margin-left: 18px;
     border-left: none;
}
