@media only screen and (max-width: 600px){
.feat_img img{
     height: 100px;
    width: 100px;
    padding-top: 5px; 
   
}
}
@media only screen and (min-width: 600px){
    .feat_img img{
         height: 150px;
        width: 150px;
        padding-top: 5px; 
       
    }
    .feature_slider_bg:before{
        content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
      height: 50%;
      opacity: 1;
      background-image: url(../assets/images/banner/bg_feature_slide.png);
      /* background-attachment: fixed; */
      background-repeat: no-repeat;
      background-position: 0% 0;
      background-size: contain;
      text-align: left;
      float: left;
         /* background-attachment: fixed; */
    }
    }

/*
.category-wrapper .category-inner:after{content:"";position:absolute;top:-1px;left:-1px;right:-1px;bottom:-1px;border-radius:100%;z-index:-1;border:2px dashed #ff7004;opacity:0;transition:all .3s ease 0s}
*/
.category-single-item img:hover{
    /* transform:scale(1.01) ; */
    /* box-shadow: 1px 0 10px 10px rgb(0 0 0 / 20%); */
    /* border:2px solid #908b8b; */
       /* border-radius: 10px; */
       /* background-color: #0c6942c7; */
       /* color:#fff !important; */
       padding: 5px 5px;
       border: 1px solid #ff7004;
       border-radius: 50%;
   }
.category-wrapper .category-inner:after {
    /* content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 100%;
    z-index: -1;
    /* border: 2px dashed #ff7004; */
    /* opacity: 0;
    transition: all .3s ease 0s; */
}
.category-wrapper .category-inner {
    display: flex;
    width: 180px;
    height: 180px;
    /* border: 1px solid #474747; */
    justify-content: center;
    border-radius: 50%;
    transition: all .3s ease 0s;
    position: relative;
    margin: auto;
  
}
.feature_slider_bg{
    position: relative;
}

.feature_slider_bg .swiper-slide .title{
    color:#c32130 !important;
}
.feat_img{
    /* border-radius: 10px;
    -moz-box-shadow: 10px 10px 50px #ccc;
    -webkit-box-shadow: 10px 10px 50px #ccc;
    box-shadow: 10px 10px 50px #ccc; */
}
.feature_slider_bg .category-wrapper{
    padding-left: 20%;
} 
@media only screen and (max-width: 479px)
{
    .category-wrapper .category-inner {
        width: 130px;
        height: 140px;
    }
}
.category-wrapper .category-inner .category-single-item .title {
    display: block;
    font-family: Raleway,sans-serif;
    font-weight: 500;
    color: #474747;
    margin: 12px 0 0;
    transition: all .3s ease 0s;
}
.feature_img_bg{
    /* border-radius: 50%; */
    /* padding-top: 7px; */
    /* background-color: #a3bac5;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    box-shadow: 2px 5px #a3bac55e; */
    /* height: 100vh;
    width: 100%; */
}

