.dd-list, .dd-list ul{
    margin:0;
    padding:0;
    list-style-type:none;
    list-style-position:outside;
    position:relative;
    line-height:1.5em;
}
.dd-list >li> a{
    font-weight: 1000;
}
.dd-list >li>ul>li> a{
    font-weight: 600;
}

.dd-list a:link, .dd-list a:active, .dd-list a:visited{
    display:block;
    padding:0px 5px;
    /* border:1px solid #333; */
    /* color:#fff; */
    text-decoration:none;
    /* background-color:#333; */
 }
 .dd-list li ul ul {
    left:2em;
    margin:0px 0 0 10px;
    }
    .dd-list li ul {
        left:1em;
        margin:0px 0 0 10px;
        }   
 /* .dd-list li{
    float:left;
    position:relative;
} */
/* .dd-list ul {
    position:absolute;
    width:12em;
    top:1.5em;
    display:none;
} */

#nav a:hover{
    background-color:#fff;
    color:#333;
}