#leading_blog a{
    color: #000 !important;
    font-weight: 700;
    /* text-decoration: underline; */
  }
  .blog-title{

    text-transform: capitalize !important;
   }

.single-review .review-img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 90px;
    flex: 0 0 90px;
    margin: 0 15px 0 0;
   border-radius: 50%;
   max-width: 50px;
   max-height: 50px;
   box-shadow: 0 0 15px 10px rgb(0 0 0 / 6%);
}
.review-img img{
  max-width: 50px;
    max-height: 50px;
}