.sidebar-widget{
    /* background-color: #fff; */
}

#leading_blogs a{
    color: green !important;
  }
  .blog-post-content-inner .blog-title a{
      white-space: pre-wrap;
  }
  blockquote{
      padding-left:60px ;
  }
  