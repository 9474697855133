
/* @color: #27aae1; */

.hex {
    margin-top: 30px;
    width: 104px;
    height: 60px;
    background-color: #27aae1;
    border-color: #27aae1;
    position: relative;
    display: inline-block;
}
.hex:before {
    content: " ";
    width: 0; height: 0;
    border-bottom: 30px solid;
    border-color: inherit;
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
    position: absolute;
    top: -30px;
}
.hex:after {
    content: "";
    width: 0;
    position: absolute;
    bottom: -30px;
    border-top: 30px solid;
    border-color: inherit;
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
}

.flex-row_thumb {
    flex-direction: row;
    display: flex;
}

.flex-column_thumb {
    flex-direction: column;
    display: flex;
}

.flex-body_thumb {
    display: flex;
    padding-left: 5px;
    padding-right: 5px;
    align-items: center;
}
.flex-body_thumb img{
    margin-right: 5px;
    height: auto;
    width: auto;
}