.clsSearchCollapse{
    display: none;
}
li.old{
    text-decoration: line-through;
    color: #ee7600;
}
span.old{
    padding-right: 10px;
    text-decoration: line-through;
    color: #ee7600;
}
.rating__star {
    cursor: pointer;
    /*color: #dabd18b2;*/
    /* color: #FFD700; */
 }
 .rating-product i{
   font-size: 20px !important;
 }
 #pro_sm_desc{
     text-align: left;
 }
 .description-review-bottom .product-description-wrapper p{
     width: 100%;
 }