.header-actions .header-action-btn .header-action-num{
    /* top: 12px; */
    /* right: 66px; */
}
.header-actions .header-action-btn{
    display: flex;
    flex-direction: column;

}
.coupon_apply{
    margin-right: 15px;
    margin-top: 20px;
}
.coupon_apply .btn{
    width: 100px !important;
    height: 30px;
    line-height: 0px;
    float: left;
}
.coupon_apply input{
  height: 30px;
  border-radius: 12px;
  padding-left: 13px;
}