.hero-bg-image img{
    /* width:100%; */
    /* height: 600px; */
    height: 400px;
}
.hero_home {
    height: 400px; 
   
}
.hero-slider{
    height: 400px; 
    background-size: contain !important;
    /* position:static; */
    /* top:10px; */
    /* margin-left: 10px; */
}
@media only screen and (max-width: 600px)
{.hero-bg-image img{
    /* width:100%; */
    height: 300px;
}
.hero_home {
    height: 300px;
}
.hero-slider{
    height: 300px;
    background-size: contain;
    /* position:static; */
    /* top:10px; */
    /* margin-left: 10px; */
}
.hero-slide-content.hero-slide-content-2{
    margin-top: 20% !important;
}
   
}
.sider_top{
    width: auto;
    max-height: 50%;
    margin: 5px 5px;
    border-radius: 5px;
}
.flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.flex-container img{
    flex:1;
}
.hero-slide-content .text-center {
    text-align: left;
}
.hero-slide-content .hero-slide-content-2{
    margin-top:20px;
}